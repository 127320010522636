import { getAuth, signInAnonymously } from 'firebase/auth';
import { useState } from 'react';
import ContentView from './Views/ContentView';
import Loading from './Library/Loading';
import { IsAuthenticated } from './Authentication';

function App() {
  const auth = getAuth();

  const [isAuthenticated, setIsAuthenticated] = useState(IsAuthenticated());

  if (!isAuthenticated) {
    signInAnonymously(auth).then(() => {
      setIsAuthenticated(true);
    });
  }

  return (
    <div className='flex min-h-screen'>
      {!isAuthenticated ? <Loading /> : <ContentView />}
    </div>
  );
}

console.log('App version 1.0');

export default App;
