import 'firebase/database';
import { ref, set, get, onValue } from 'firebase/database';
import { GetDatabase } from '../DatabaseConfiguration';

const database = GetDatabase();

export function GetMoneyDonated() {
  return new Promise((resolve, reject) => {
    get(ref(database, 'moneyDonated'))
      .then((moneyDonatedSnapshot) => {
        resolve(moneyDonatedSnapshot.val());
      })
      .catch((response) => {
        reject('Failed to retrieve money donated:', response);
      });
  });
}

export function Donate(amount) {
  return new Promise((resolve, reject) => {
    return GetMoneyDonated().then((response) => {
      const totalMoneyDonated = Number.parseFloat(
        Number(response + amount).toFixed(2)
      );
      setMoneyDonated(totalMoneyDonated).then(resolve).catch(reject);
    });
  });
}

function setMoneyDonated(amount) {
  return new Promise((resolve, reject) => {
    set(ref(database, 'moneyDonated'), amount)
      .then(resolve)
      .catch((response) => {
        reject('Failed to set money donated:', response);
      });
  });
}

export function IsDonationValid(amount) {
  return amount > 0 && amount < 1000000;
}

export function SetTotalMoneyDonatedListenerAndCallback(
  updateTotalMoneyDonatedCallback
) {
  onValue(ref(database, 'moneyDonated'), (moneyDonatedSnapshot) => {
    console.log('Database/moneyDonated update event:', moneyDonatedSnapshot);
    updateTotalMoneyDonatedCallback(moneyDonatedSnapshot.val());
  });
}
