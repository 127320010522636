import React, { useState } from 'react';
import Button from '../Library/Button';
import Controller from '../Controllers/ContentController';
import Error from '../Library/Error';
import Loading from '../Library/Loading';
import LineSpacer from '../Library/LineSpacer';
import Input from '../Library/Input';
import GeorgeWalin from '../GeorgeWalin.jpg';
import CartonJames from '../CartonJames.jpg';
import JeneWildder from '../JeneWildder.jpg';
import Banner from '../Banner.jpg';

const Content = React.memo(() => {
  const [totalMoneyDonated, setTotalMoneyDonated] = useState(0);
  const [controllerStatus, setControllerStatus] = useState('Loading');
  const [customDonationAmountInput, setCustomDonationAmountInput] = useState();
  const [customDonationAmount, setCustomDonationAmount] = useState();
  const [totalMoneyDonatedJustChanged, setTotalMoneyDonatedJustChanged] =
    useState(false);

  const experts = [
    {
      contactLink:
        'https://docs.google.com/forms/d/e/1FAIpQLSfVGOwOWjbwm57kpqLO_3tHFEkCpzTfTb675PhcZHrdAAoJcQ/viewform?usp=sf_link',
      name: 'George Walin',
      title: 'Genius',
      image: GeorgeWalin,
    },
    {
      contactLink:
        'https://docs.google.com/forms/d/e/1FAIpQLSfXCinDV9-sh10bRfcQ1TtHHnPamcTtLhMGeQJ8oBvsTeOYOw/viewform?usp=sf_link',
      name: 'Carton James',
      title: 'Author, "Uncle Scam?: The Story of American Evil"',
      image: CartonJames,
    },
    {
      contactLink:
        'https://docs.google.com/forms/d/e/1FAIpQLSdWX8Ja2QUZ5PcWpYtG9M5MfU9AfQrnNDmExBI1AqUIgrLC-A/viewform?usp=sf_link',
      name: 'Jene Wildder',
      title: 'Host, "Evil in My Area" Podcast',
      image: JeneWildder,
    },
  ];

  const controller = new Controller(
    setControllerStatus,
    totalMoneyDonated,
    setTotalMoneyDonated,
    indicateTotalMoneyDonatedJustChanged
  );

  //controller.GetTotalMoneyDonated(setTotalMoneyDonated);

  function onClickDonateMoney(amount) {
    if (!totalMoneyDonatedJustChanged)
      controller.Donate(amount, setTotalMoneyDonated);
  }

  function onChangeCustomDonationAmount(text) {
    setCustomDonationAmountInput(text);
    const donationAmount = Number.parseFloat(Number(text).toFixed(2));
    if (text) {
      setCustomDonationAmount(donationAmount);
    } else {
      setCustomDonationAmount();
    }
  }

  function indicateTotalMoneyDonatedJustChanged() {
    setTotalMoneyDonatedJustChanged(true);
    setTimeout(() => {
      setTotalMoneyDonatedJustChanged(false);
    }, 600);
  }

  return (
    <div className='w-full bg-gray-100'>
      {controllerStatus === 'Loading' ? (
        <Loading />
      ) : controllerStatus === 'Error' ? (
        <Error />
      ) : (
        <div label='Loaded Content'>
          <div
            label='Wide Angle Picture Section'
            className='py-12 px-4'
            style={{
              backgroundImage: `url(${Banner})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'right top',
            }}
          >
            <div className='grid place-items-center pt-72'>
              <p className=' text-4xl text-red-700 font-bold text-center px-8 py-4 rounded-xl bg-gray-100 border border-gray-400 shadow-sm'>
                JOIN THE REVOLUTION
              </p>
            </div>
          </div>
          <div label='Donation Section'>
            <div className='p-6'>
              <TotalMoneyDonated />
              <p className='text-center font-bold pb-2 text-xl pt-8'>
                DONATE TO OUR CAUSE
              </p>
              <DonationButtons
                customDonationAmount={customDonationAmount}
                customDonationAmountInput={customDonationAmountInput}
                onChangeCustomDonationAmount={onChangeCustomDonationAmount}
                controller={controller}
                onClickDonateMoney={onClickDonateMoney}
              />
              <LineSpacer />
              <p className='text-center font-semibold text-xl'>
                Every dollar goes toward the development of a bomb to destroy
              </p>
              <p className='text-center font-black pb-2 text-3xl'>
                THE DARK HOUSE
              </p>
            </div>
          </div>
          <div label='Ask the Experts Section'>
            <div className='bg-white p-4 border-t border-gray-300 shadow'>
              <p className='text-center font-bold text-2xl'>ASK THE EXPERTS</p>
              <div className='md:flex md:px-10'>
                {experts.map((expert) => (
                  <div className='px-10 pb-8 pt-4'>
                    <ExpertContactCard expert={expert} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            label='Footer'
            className='bg-gray-100 py-8 border-t border-gray-300 shadow'
          >
            <p className='text-center text-gray-700'>© 2023, Origins Inc.</p>
          </div>
        </div>
      )}
    </div>
  );

  function ExpertContactCard({ expert }) {
    return (
      <a href={expert.contactLink} target='_blank'>
        <div className=' bg-gray-200 rounded-b-lg border-b border-x border-gray-300 shadow hover:bg-gray-300'>
          <img src={expert.image} />
          <p className='text-center pt-4 text-lg font-semibold px-4'>
            {expert.name}
          </p>
          <p className='text-center pb-4 px-4'>{expert.title}</p>
        </div>
      </a>
    );
  }

  function TotalMoneyDonated() {
    return (
      <div className='grid place-items-center'>
        <div className='grid place-items-center rounded-xl border border-gray-300 bg-white px-8 py-2 shadow-sm'>
          <p className='text-3xl font-semibold relative'>
            $
            {totalMoneyDonated.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            {totalMoneyDonatedJustChanged ? (
              <p
                className={
                  'text-3xl text-red-700 font-semibold animate-ping absolute top-0'
                }
              >
                $
                {totalMoneyDonated
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </p>
            ) : null}
          </p>
        </div>
        <p className='text-center font-semibold'>DONATED</p>
      </div>
    );
  }
});

function DonationButtons({
  customDonationAmount,
  customDonationAmountInput,
  onChangeCustomDonationAmount,
  controller,
  onClickDonateMoney,
}) {
  const staticDonationButtonStyle = 'py-2 flex justify-center h-14';
  console.log(customDonationAmount);
  return (
    <div className='flex justify-center'>
      <div className='md:flex md:gap-8'>
        <Button
          text='$1'
          onClick={() => onClickDonateMoney(1)}
          className={staticDonationButtonStyle}
        />
        <Button
          text='$5'
          onClick={() => onClickDonateMoney(5)}
          className={staticDonationButtonStyle}
        />
        <Button
          text='$20'
          onClick={() => onClickDonateMoney(20)}
          className={staticDonationButtonStyle}
        />
        <Button
          text='$50'
          onClick={() => onClickDonateMoney(50)}
          className={staticDonationButtonStyle}
        />
        <Button
          text='$100'
          onClick={() => onClickDonateMoney(100)}
          className={staticDonationButtonStyle}
        />
        <div>
          <Input
            className='py-2 flex justify-center'
            placeholderText='Other Amount'
            type='number'
            onChangeCallback={onChangeCustomDonationAmount}
            text={customDonationAmountInput}
          />
          {controller.IsDonationValid(customDonationAmount) ? (
            <Button
              text={`Give $${customDonationAmount.toFixed(2)}`}
              className='flex justify-center pb-2'
              onClick={() => onClickDonateMoney(customDonationAmount)}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Content;
