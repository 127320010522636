import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';

function Loading() {
  return (
    <div className='w-full'>
      <div className='grid h-screen place-items-center animate-pulse'>
        <EllipsisHorizontalIcon className='h-24 w-24' />
      </div>
    </div>
  );
}

export default Loading;
