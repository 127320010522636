import { onValue } from 'firebase/database';
import * as ContentModel from '../Models/ContentModel';

class Controller {
  constructor(
    setControllerStatus,
    totalMoneyDonated,
    setTotalMoneyDonated,
    indicateTotalMoneyDonatedJustChanged
  ) {
    this.setControllerStatus = setControllerStatus;
    this.setTotalMoneyDonated = setTotalMoneyDonated;
    this.totalMoneyDonated = totalMoneyDonated;
    this.indicateTotalMoneyDonatedJustChanged =
      indicateTotalMoneyDonatedJustChanged;
    this.SubscribeToTotalMoneyDonatedChanges();
  }

  IsDonationValid(amount) {
    return ContentModel.IsDonationValid(amount);
  }

  GetTotalMoneyDonated() {
    ContentModel.GetMoneyDonated()
      .then((response) => {
        this.setTotalMoneyDonated(response);
        this.setControllerStatus('Loaded');
      })
      .catch((response) => {
        console.log(
          'GetTotalMoneyDonated - ContentModel.GetMoneyDonated catch:',
          response
        );
        this.setControllerStatus('Error');
      });
  }

  Donate(amount) {
    if (this.IsDonationValid(amount)) {
      ContentModel.Donate(amount).catch((response) => {
        console.log('Donate - ContentModel.Donate catch:', response);
        this.setControllerStatus('Error');
      });
    }
  }

  SubscribeToTotalMoneyDonatedChanges() {
    ContentModel.SetTotalMoneyDonatedListenerAndCallback(
      (totalMoneyDonated) => {
        console.log(totalMoneyDonated, this.totalMoneyDonated);
        if (totalMoneyDonated !== this.totalMoneyDonated) {
          this.setTotalMoneyDonated(totalMoneyDonated);
          this.setControllerStatus('Loaded');
          this.indicateTotalMoneyDonatedJustChanged();
        }
      }
    );
  }
}

export default Controller;
