function Error() {
  return (
    <div className='w-full'>
      <div className='grid h-screen place-items-center'>
        <p className='font-semibold text-gray-800 text-xl'>
          Something went wrong. Try refreshing
        </p>
      </div>
    </div>
  );
}

export default Error;
